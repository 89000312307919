
"use client";

import "../../../styles/pages/common.css"

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import XIcon from '@mui/icons-material/X';
import { loginWith } from "@wt/utilities/login";
import Link from "next/link";
import { useRouter } from "next/navigation";

export const LoginContent = () => {
  const router = useRouter();

  return (
    <div className="flex h-full flex-1 grow flex-col items-center px-4 md:px-8">
      <div className="my-6 flex max-w-4xl flex-col space-y-6 rounded-3xl p-4 text-lg md:bg-base-200 md:p-6">
        <div className="flex w-full flex-row items-center justify-between">
          <div onClick={() => router.back()}>
            <ChevronLeftRoundedIcon className="m-0 fill-current text-2xl leading-none hover:cursor-pointer" />
          </div>
          <div className="mx-20 grow text-center">
            <span className="text-xl font-medium lg:text-4xl">Login</span>
          </div>
          <Link
            href={"/game"}
            className="hidden md:flex"
          >
            <CloseRoundedIcon className="m-0 fill-current text-2xl leading-none" />
          </Link>
        </div>
        <SocialLoginButtons />
      </div>
    </div>
  );
}

const SocialLoginButtons = () => {
  return (
    <>
      <GoogleLoginButton />
      <TwitterLoginButton />
      {/* <FacebookLoginButton /> */}
    </>
  );
}

const GoogleLoginButton = () => {
  return (
    <div className="btn btn-primary" onClick={() => loginWith('google')}>
      <GoogleIcon className="pr-1" /> Login with Google
    </div>
  );
}

const TwitterLoginButton = () => {
  return (
    <div className="btn btn-primary" onClick={() => loginWith('twitter')}>
      <XIcon className="pr-1" /> Login with X
    </div>
  );
}

const FacebookLoginButton = () => {
  return (
    <div className="btn btn-primary" onClick={() => loginWith('facebook')}>
      <FacebookRoundedIcon className="pr-1" /> Login with Facebook
    </div>
  );
}